// @import 'variables';


// Variables --------------------------

$fa-font-path:         "../webfonts" !default;
$fa-font-size-base:    16px !default;
$fa-font-display:      swap !default;
$fa-css-prefix:        fa !default;
$fa-version:           "5.13.0" !default;
$fa-border-color:      #eee !default;
$fa-inverse:           #fff !default;
$fa-li-width:          2em !default;
$fa-fw-width:          (20em / 16);
$fa-primary-opacity:   1 !default;
$fa-secondary-opacity: .4 !default;

// Convenience function used to set content property
@function fa-content($fa-var) {
  @return unquote("\"#{ $fa-var }\"");
}

// 400 Reg
@font-face {
  font-family: 'Font Awesome 5 Free';
  font-style: normal;
  font-weight: 400;
  font-display: $fa-font-display;
  src: url('#{$fa-font-path}/fa-regular-400.eot');
  src: url('#{$fa-font-path}/fa-regular-400.eot?#iefix') format('embedded-opentype'),
  url('#{$fa-font-path}/fa-regular-400.woff2') format('woff2'),
  url('#{$fa-font-path}/fa-regular-400.woff') format('woff'),
  url('#{$fa-font-path}/fa-regular-400.ttf') format('truetype'),
  url('#{$fa-font-path}/fa-regular-400.svg#fontawesome') format('svg');
}
.far {
  font-family: 'Font Awesome 5 Free';
  font-weight: 400;
}

// 900
@font-face {
  font-family: 'Font Awesome 5 Free';
  font-style: normal;
  font-weight: 900;
  font-display: $fa-font-display;
  src: url('#{$fa-font-path}/fa-solid-900.eot');
  src: url('#{$fa-font-path}/fa-solid-900.eot?#iefix') format('embedded-opentype'),
  url('#{$fa-font-path}/fa-solid-900.woff2') format('woff2'),
  url('#{$fa-font-path}/fa-solid-900.woff') format('woff'),
  url('#{$fa-font-path}/fa-solid-900.ttf') format('truetype'),
  url('#{$fa-font-path}/fa-solid-900.svg#fontawesome') format('svg');
}
.fa,.fas {
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
}

// Brands
@font-face {
  font-family: 'Font Awesome 5 Brands';
  font-style: normal;
  font-weight: 400;
  font-display: $fa-font-display;
  src: url('#{$fa-font-path}/fa-brands-400.eot');
  src: url('#{$fa-font-path}/fa-brands-400.eot?#iefix') format('embedded-opentype'),
  url('#{$fa-font-path}/fa-brands-400.woff2') format('woff2'),
  url('#{$fa-font-path}/fa-brands-400.woff') format('woff'),
  url('#{$fa-font-path}/fa-brands-400.ttf') format('truetype'),
  url('#{$fa-font-path}/fa-brands-400.svg#fontawesome') format('svg');
}
.fab {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}



.fa,.fab,.fad,.fal,.far,.fas {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1
}
.fa-lg {
    font-size: 1.33333em;
    line-height: .75em;
    vertical-align: -.0667em
}
.fa-xs {
    font-size: .75em
}
.fa-sm {
    font-size: .875em
}
.fa-1x {
    font-size: 1em
}
.fa-2x {
    font-size: 2em
}
.fa-3x {
    font-size: 3em
}
.fa-4x {
    font-size: 4em
}
.fa-5x {
    font-size: 5em
}
.fa-6x {
    font-size: 6em
}
.fa-7x {
    font-size: 7em
}
.fa-8x {
    font-size: 8em
}
.fa-9x {
    font-size: 9em
}
.fa-10x {
    font-size: 10em
}
.fa-fw {
    text-align: center;
    width: 1.25em
}
.fa-ul {
    list-style-type: none;
    margin-left: 2.5em;
    padding-left: 0
}
.fa-ul>li {
    position: relative
}
.fa-li {
    left: -2em;
    position: absolute;
    text-align: center;
    width: 2em;
    line-height: inherit
}
.fa-border {
    border: .08em solid #eee;
    border-radius: .1em;
    padding: .2em .25em .15em
}
.fa-pull-left {
    float: left
}
.fa-pull-right {
    float: right
}
.fa.fa-pull-left,.fab.fa-pull-left,.fal.fa-pull-left,.far.fa-pull-left,.fas.fa-pull-left {
    margin-right: .3em
}
.fa.fa-pull-right,.fab.fa-pull-right,.fal.fa-pull-right,.far.fa-pull-right,.fas.fa-pull-right {
    margin-left: .3em
}
.fa-inverse {
    color: #fff
}

.fa-instagram:before {
    content: "\f16d"
}
.fa-behance:before {
    content: "\f1b4"
}
.fa-pinterest-p:before {
    content: "\f231"
}
.fa-facebook-f:before {
    content: "\f39e"
}
.fa-dribbble:before {
    content: "\f17d"
}
.fa-twitter:before {
    content: "\f099"
}
.fa-flickr:before {
    content: "\f16e"
}
.fa-adobe:before {
    content: "\f778"
}
.fa-map-marker-alt:before {
    content: "\f3c5"
}
.fa-phone-square:before {
    content: "\f098"
}
.fa-envelope:before {
    content: "\f0e0"
}
.fa-quote-left:before {
    content: "\f10d"
}
.fa-external-link-alt:before {
    content: "\f35d"
}
.fa-arrow-down:before {
    content: "\f063"
}
.fa-exclamation-triangle:before {
    content: "\f071"
}
.sr-only {
    border: 0;
    clip: rect(0,0,0,0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px
}
.sr-only-focusable:active,.sr-only-focusable:focus {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    position: static;
    width: auto
}
