.menu-back.light{
	background: rgba(255,255,255,0);
	border-bottom:1px solid rgba(130,130,130,0);
}
.cbp-af-header.light.cbp-af-header-shrink {
	background: rgba(250,250,250,0.875);
  border-bottom: 1px solid rgba(130,130,130,0.1);
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
}
.cbp-af-header.cbp-af-header-shrink .logo.light,
.logo.light{
}
.menu.light > ul > li a{
	color: var(--grey-3);
}
.menu.light > ul > li > ul a,
.menu.light > ul > li a.social,
.cbp-af-header.cbp-af-header-shrink .menu.light > ul > li > ul > li a,
.cbp-af-header.cbp-af-header-shrink .menu.light > ul > li a.social,
.menu.light h5,
.menu.light h4,
.menu.light .subtext {
	color:#000;
}
.menu.light > ul > li > ul {
    background: rgba(253,253,253,.95);
	box-shadow: 0 3px 3px 1px rgba(100,100,100,.1);
}
.cbp-af-header.cbp-af-header-shrink .menu.light > ul > li > ul > li a:hover,
.menu.light > ul > li > ul > li a:hover {
	color: #fff !important;
}
.menu.light > ul > li > ul > li p {
	color:#000;
    border-bottom: 1px solid rgba(120,120,120,.2);
}
.menu.light > ul > li > ul.normal-sub > li a span.go-left-icon,
.menu.light > ul > li > ul.normal-sub > li a span.go-right-icon {
	background-image:url('../images/arrow-black.svg');
}
.menu.light > ul > li > ul.normal-sub > li ul.go-left,
.menu.light > ul > li > ul.normal-sub > li ul.go-right {
    background: #fff;
	box-shadow: 0 3px 3px 1px rgba(100,100,100,.1);
}

ul.tabs.light li{
	color: #000;
	border-right:1px solid #dbdbdb;
	background-color:#f0f0f0;
}
ul.tabs.light li:hover,
ul.tabs.light li.current,
.tab-content.light,
.menu.light .form-section{
	background-color:#f9f9f9;
}
.menu.light .marquee span {
	color: var(--grey-2);
}
.menu.light #ajax-form textarea,
.menu.light #ajax-form input {
	color: #000;
	border-bottom:1px solid rgba(0,0,0,.3);
}
.menu.light #ajax-form input:-ms-input-placeholder  {
	color:#000;
	opacity:.5;
}
.menu.light #ajax-form input::-moz-placeholder  {
	color:#000;
	opacity:.5;
}
.menu.light #ajax-form input:-moz-placeholder  {
	color:#000;
	opacity:.5;
}
.menu.light #ajax-form input::-webkit-input-placeholder  {
	color:#000;
	opacity:.5;
}
.menu.light #ajax-form textarea:-ms-input-placeholder  {
	color:#000;
	opacity:.5;
}
.menu.light #ajax-form textarea::-moz-placeholder  {
	color:#000;
	opacity:.5;
}
.menu.light #ajax-form textarea:-moz-placeholder  {
	color:#000;
	opacity:.5;
}
.menu.light #ajax-form textarea::-webkit-input-placeholder  {
	color:#000;
	opacity:.5;
}

/* #Menu background is not trasparent
================================================== */

.menu-back.menu-have-back-color{
	background: rgba(0,0,0,.9);
}
.cbp-af-header.menu-have-back-color.cbp-af-header-shrink {
	background: rgba(0,0,0,.9);
}
.menu-back.light.menu-have-back-color{
	background: rgba(255,255,255,1);
}
.cbp-af-header.light.menu-have-back-color.cbp-af-header-shrink {
	background: rgba(255,255,255,.9);
}


/* #Menu align left, logo right
================================================== */

.cbp-af-header.cbp-af-header-shrink .logo.move-for-left{
	left:auto;
	right:0;
}
.logo.move-for-left{
	left:auto;
	right:0;
}
.menu.move-for-left > ul {
	float:none;
}


/* #Menu center, logo top
================================================== */

.menu.menu-center-logo-top {
	margin:0 auto;
}
.menu.menu-center-logo-top > ul {
	float:none;
	margin:0 auto;
	width:100%;
	text-align:center;
}
.menu.menu-center-logo-top > ul > li {
	float:none;
    margin: 0 auto;
	display:inline-block;
	text-align:left;
}
.cbp-af-header.cbp-af-header-shrink .logo.menu-center-logo-top{
	top:25px;
	left:50%;
	transform: translateX(-50%);
}
.logo.menu-center-logo-top{
	top:35px;
	left:50%;
	transform: translateX(-50%);
}
.menu-container.menu-center-logo-top{
	padding-top:70px;
	transition : all 0.3s ease-out;
}
.cbp-af-header.cbp-af-header-shrink .menu-container.menu-center-logo-top {
	padding-top:55px;
	transition : all 0.3s ease-out;
}
