#owl-sep-1 {
	position:relative;
	width:100%;
	max-width:950px;
	display:block;
}
#owl-sep-1 .item{
	position:relative;
	width:100%;
	margin-left:0 auto;
	z-index:2;
	display:block;
}
.quote {padding-bottom: 35px;}
.quote h4 {
	text-align: left;
	// font-style: italic;
	font-family: var(--ff-unique);
	font-size: 1.3em;
	line-height: 1.65;
	color: var(--secondary);
	letter-spacing:.25px;
	width:100%;
}
.quote h4 i {
	padding: 0px 25px 195px 2px;
	float: left;
}
// .quote h4:before {
// 	content: "\f10d" !important;
// 	font-family: "Font Awesome";
// 	font-size: 3em;
// 	color: var(--grey-2);
// 	position:relative;
// 	padding:10px;
// 	margin:0 auto;
// 	overflow:hidden;
// 	display:block;
// 	background-image:url('../images/qu.png');
// 	background-repeat:no-repeat;
// 	background-position: 0 0;
// 	background-size: 18px 11px;
// }
.quote p {
	text-align: left;
	color: var(--dark);
	font-family: var(--ff-head);
	font-weight: 400;
	font-size: 1.25em;
	line-height: 1.4;
	// letter-spacing:1px;
	width:100%;
	margin-top:25px;
}
#owl-sep-1.owl-theme .owl-controls{
	position:absolute;
	left:0;
	text-align: left;
	bottom:0;
	z-index:100;
	width:100%;
	z-index:20;
}
#owl-sep-1.owl-theme .owl-controls .owl-page span{
	background: var(--grey-2);
	border-radius:50%;
	width:7px;
	height:7px;
	margin-right:12px;
	margin-top: 5px;
  transition: all 200ms linear;
}
#owl-sep-1.owl-theme .owl-controls .owl-page span:hover{
	-webkit-transform: scale(1.5);
	transform: scale(1.5);
}
#owl-sep-1.owl-theme .owl-controls .owl-page.active span {
	-webkit-transform: scale(1.5);
	transform: scale(1.5);
}
