html {scroll-behavior: smooth;}
body#candidatepage .menu.light > ul > li:nth-child(1) a, body#graphicpage .menu.light > ul > li:nth-child(1) a:hover,
body#clientpage .menu.light > ul > li:nth-child(2) a, body#webpage .menu.light > ul > li:nth-child(2) a:hover,
body#knowledgepage .menu.light > ul > li:nth-child(3) a, body#brandpage .menu.light > ul > li:nth-child(3) a:hover,
body#contactpage .menu.light > ul > li:nth-child(4) a, body#marketpage .menu.light > ul > li:nth-child(4) a:hover,
{color: var(--primary);}

h1{letter-spacing: -1.5px !important}
h2 {
  font-family: var(--ff-sans) !important;
  color: var(--secondary) !important;
  font-size: 1.88em !important;
}
// "web design" drop shadows (unused)
.drop-shadow {
  box-shadow: 0 0 5px rgba(0,0,0,0.15), 0 0 3px rgba(0,0,0,0.12);
  transition: all 0.3s cubic-bezier(.25,.8,.25,1);
  border-radius: 3px 3px 0 0;
}
.drop-shadow:hover {
  box-shadow: 0 8px 15px rgba(0,0,0,0.20), 0 6px 6px rgba(0,0,0,0.16);
}
.invert {
  -webkit-filter: invert(100%);
  filter: invert(100%);
}
.tech-font {
  font-size: 12px;
  color: var(--grey-4);
  font-family: var(--ff-unique);
  font-size: 10px;
  letter-spacing: 2px;
  text-transform: uppercase;
}
.learn-more {
  z-index: 5;
  position: absolute;
  bottom: 2%;
  left: 5%;
  cursor: pointer;
}
.learn-more:hover {
  color: var(--primary);
}
@keyframes bounce {
  from,
  20%,
  53%,
  80%,
  to {
    transform: translate3d(0, 0, 0);
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }
  40%,
  43% {
    transform: translate3d(0, -30px, 0);
    animation-timing-function: cubic-bezier(.755, .05, .855, .06);
  }
  70% {
    transform: translate3d(0, -15px, 0);
    animation-timing-function: cubic-bezier(.755, .05, .855, .06);
  }
  90% {
    transform: translate3d(0, -4px, 0);
  }
}
.bounce {
  transform-origin: center bottom;
  animation-name: bounce;
  -webkit-animation: bounce 2s infinite;
  animation: bounce 2s infinite;
}

.highlight {
  background: var(--primary);
  display: inline;
  color: #fff !important;
  padding:0 1px;
}
.cta-box {
  display:flex;
  justify-content:space-between;
  outline: solid 2px var(--grey-1);
  padding: 30px 40px;
  align-content: center;
  font-size: 1.35em;
  // background-color: var(--lite);
}
.cta-box:hover {
  outline-color: var(--primary);
}
.cta-box h4 {
  margin: auto 10px auto 0;
  font-size: 2.1vw;
  letter-spacing: .5px;
}
.cta-button {
  background-color: #fff;
  border: solid 2px var(--primary);
  padding: 12px 15px;
  margin: auto 0 auto 10px;
  color: var(--primary);
  font-family: var(--ff-head);
  vertical-align: middle;
  cursor: pointer;
  white-space: nowrap;
  line-height: normal;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  outline: none;
}
.cta-button:hover {
  // border-color: var(--primary);
  color: #fff;
  background-color: var(--primary);;
}
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0,0,0,0);
  border: 0;
}
.center {text-align: center; margin:0 auto;}
.nobr {white-space: nowrap;}
.columns p.center {margin-top: 5px;}
.mx-2px {margin-left: 2px; margin-right: 2px;}

.example-image-link img {opacity:1; transition: all 300ms ease-in-out;}
.example-image-link img:hover {opacity:.925; transition: all 300ms ease-in-out;}
.img-scroll {
    width: auto;
    max-height: 244px;
    overflow: scroll;
    border: 1px solid var(--grey-1);
  }
.img-wrap.img-scroll img {
    text-align: center;
    margin: auto;
    max-width: 100%
  }
.img-scroll.tall {max-height: 410px; margin-bottom: 5px;}


/* entire container, keeps perspective */
.flip-container {
  perspective: 1000;
}
/* flip the pane when hovered */
.flip-container:hover .flipper, .flip-container.hover .flipper {
  transform: rotateY(180deg);
}

.flip-container, .front, .back {
  width: 160px;
  height: 78px;
}

/* flip speed goes here */
.flipper {
  transition: 0.6s;
  transform-style: preserve-3d;
  position: relative;
}

/* hide back of pane during swap */
.front, .back {
  backface-visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
}

/* front pane, placed above back */
.front {z-index: 2;}

/* back, initially hidden pane */
.back {transform: rotateY(180deg);}
.flip-container:hover .back{z-index : 10;}
.back{z-index:0;}


/* ---------------------------------------------- /*
 * Mouse animate icon
/* ---------------------------------------------- */
.mouse-icon {
  border: 1px solid var(--grey-4);
  border-radius: 12px;
  height: 16px;
  width: 10px;
  display: block;
  z-index: 10;
  opacity: 0.8;
  line-height: 16px;
  vertical-align: middle;
  margin: 2px 0 0 10px;
  float: right;
}
.mouse-icon .wheel {
  animation-name: drop;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-delay: 0s;
  animation-iteration-count: infinite;
  animation-play-state: running;
}
.mouse-icon .wheel {
  position: relative;
  border-radius: 10px;
  background: var(--grey-4);
  width: 2px;
  height: 6px;
  top: 4px;
  margin-left: auto;
  margin-right: auto;
}
@keyframes drop {
  0% {
    top: 1px;
    opacity: 0;
  }
  30% {
    top: 3px;
    opacity: 1;
  }
  100% {
    top: 5px;
    opacity: 0;
  }
}
.two-img-1-box.portfolio-box-1 img {
  width: 50%;
  display: inline;
}
