/* ––––––––––––––––––––––––––––––––––––––––––––––––––
Menu Mobile style's
–––––––––––––––––––––––––––––––––––––––––––––––––– */

@media only screen and (max-width: 1199px) {

	.menu img {
		width:calc(100% - 20px);
		display:block;
	}
	.video-section{
		max-width:calc(100% - 20px);
	}
	.menu-container{
		width:calc(100% - 80px);
		max-width:calc(100% - 80px);
		margin:0 auto;
	}
	.menu > ul {
	}
	.menu-back{
		background: rgba(0,0,0,1);
		transition : all 0.3s ease-out;
	}
	.cbp-af-header.cbp-af-header-shrink {
		background: rgba(0,0,0,1);
		transition : all 0.3s ease-out;
	}
	.cbp-af-header.cbp-af-header-shrink .logo{
		position:absolute;
		height:36px;
		z-index:10000;
		top:18px;
		transition : all 0.3s ease-out;
	}
	.cbp-af-header.cbp-af-header-shrink .logo img{
		height:36px;
		display:block;
	}
	.logo{
		position:absolute;
		height:50px;
		z-index:10000;
		top:18px;
		transition : all 0.3s ease-out;
	}
	.logo img{
		height:70px;
		display:block;
	}
	#menu-wrap{
	}
	.cbp-af-header.cbp-af-header-shrink .menu > ul > li a {
		padding: 16px 0;
		transition : all 0.3s ease-out;
	}
	.cbp-af-header.cbp-af-header-shrink .menu > ul > li > ul > li a {
		padding:0;
		margin-left:10px;
		margin-right:10px;
		color:#c5c5c5;
		font-family: 'Open Sans';
		font-weight:500;
		font-size: 10px;
		line-height:18px;
		letter-spacing:2px;
		text-transform:uppercase;
		width: 100%;
		display: block;
	}
	.menu > ul > li a {
		padding: 16px 0;
	}
	.menu > ul > li > ul > li a {
		padding:0;
		margin-left:10px;
		margin-right:10px;
		color:#c5c5c5;
		font-family: 'Open Sans';
		font-weight:500;
		font-size: 10px;
		line-height:18px;
		letter-spacing:2px;
		text-transform:uppercase;
		width: 100%;
		display: block;
	}
    .menu-container {
        width: 100%;
    }
    .menu-mobile {
        display: block;
    }
	.menu-dropdown-icon-sub:before,
	.menu-dropdown-icon:before {
        display: block;
		position:relative;
    }
	.menu > ul > li > ul {
		padding:0;
		background-color:#000;
	}
    .menu > ul {
		overflow:hidden;
		width: 100%;
		float:none;
		position:relative;
        display: block;
		visibility:hidden;
		opacity: 0;
		height:0;
		transition: all 0.2s ease-in-out;
    }
    .menu > ul > li {
        width: 100%;
        float: none;
        display: block;
    }
    .menu > ul > li a {
        width: 100%;
        display: block;
    }
    .menu > ul > li > ul {
        position: relative;
    }
    .menu > ul > li > ul.normal-sub {
        width: 100%;
		padding: 0;
    }
	.menu > ul > li > ul.normal-sub > li a span.go-right-icon,
	.menu > ul > li > ul.normal-sub > li a span.go-left-icon {
		display:none;
	}
	.menu > ul > li > ul.normal-sub > li ul.go-right,
	.menu > ul > li > ul.normal-sub > li ul.go-left {
		position:relative;
		left:auto;
		top:auto;
		width: 100%;
		padding: 0 10px;
		background: #000;
		display:block;
	}
	.menu > ul > li > ul.normal-sub > li ul.go-right li a,
	.menu > ul > li > ul.normal-sub > li ul.go-left li a{
		padding: 5px 0;
	}
	.cbp-af-header.cbp-af-header-shrink .menu > ul > li > ul.normal-sub > li ul.go-right li a,
	.cbp-af-header.cbp-af-header-shrink .menu > ul > li > ul.normal-sub > li ul.go-left li a{
		padding: 5px 0;
	}
	.cbp-af-header.cbp-af-header-shrink .menu > ul > li > ul.normal-sub {
		width: 100%;
		padding: 0;
	}
	.menu > ul > li > ul.normal-sub > li {
		padding-bottom: 0;
		display:block;
	}
	.cbp-af-header.cbp-af-header-shrink .menu > ul > li > ul.normal-sub > li a {
		border: 0;
		padding: 10px 0;
	}
	.menu > ul > li > ul.normal-sub > li a {
		border: 0;
		padding: 10px 0;
	}
	.menu > ul > li > ul.normal-sub p{
		margin-left:10px;
		margin-right:10px;
		margin-top:20px;
		margin-bottom:7px;
		width:calc(100% - 20px);
	}
	.menu > ul > li > ul > li.two-col,
	.menu > ul > li > ul > li.three-col,
	.menu > ul > li > ul > li.two-thirds-col,
	.menu > ul > li > ul > li {
        float: none;
        width: 100%;
		padding-bottom:15px;
    }
    .menu > ul > li > ul > li:first-child {
        margin: 0;
    }
    .menu > ul > li > ul > li > ul {
        position: relative;
    }
    .menu > ul > li > ul > li > ul > li {
        float: none;
    }
    .menu .show-on-mobile {
        display: block;
		visibility:visible;
		opacity: 1;
		height:auto;
    }
	.form-section {
		margin-left:0;
	}
	.tab-content ul > li,
	.tab-content ul > li.three-col,
	.tab-content ul > li.two-col,
	.tab-content ul > li.two-thirds-col {
		width: 100%;
        float: none;
		padding-bottom:15px;
	}
	ul.tabs li:last-child,
	ul.tabs li{
		width:calc(100% - 40px);
		padding: 10px 20px;
		border-right:none;
		border-bottom:1px solid #161616;
	}
	.back-logo-image{
		background-image:none;
	}
	.menu > ul > li.social-mobile,
	.cbp-af-header.cbp-af-header-shrink .menu > ul > li.social-mobile {
		display: inline-block;
		width:auto;
	}
	.menu > ul > li a.social,
	.cbp-af-header.cbp-af-header-shrink .menu > ul > li a.social {
		padding: 30px 8px;
		display: inline-block;
	}
	.menu > ul > li a.social.first-icon,
	.cbp-af-header.cbp-af-header-shrink .menu > ul > li a.social.first-icon {
		padding-left:0;
	}
	.back-logo-image{
		background-size: 0 0;
	}


	/* #Light scheme menu on mobile
	================================================== */

	// .menu-back.light{
	// 	background: rgba(245,245,245,.8);
	// }
	// .cbp-af-header.light.cbp-af-header-shrink {
	// 	border-bottom:none;
	// 	background: rgba(245,245,245,1);
	// }
	.menu.light .menu-mobile:after {
		color: var(--dark);
	}
	.cbp-af-header.light.cbp-af-header-shrink .menu-mobile {
		padding-top:40px;
		padding-bottom:20px;
		transition : all 0.3s ease-out;
	}
	.menu-mobile {
		transition : all 0.3s ease-out;
	}
	.menu.light .menu-dropdown-icon:before {
		background: #fff;
		color: #000;
	}
	.menu.light > ul > li > ul {
		background: rgba(245,245,245,1);
		box-shadow:none;
	}
	.menu.light > ul {
		background: rgba(245,245,245,1);
		box-shadow:none;
	}

	.menu.light > ul > li > ul > li p {
		color:#000;
		border-bottom: 1px solid rgba(120,120,120,.2);
	}
	.menu.light > ul > li > ul.normal-sub > li ul.go-left,
	.menu.light > ul > li > ul.normal-sub > li ul.go-right {
		background: transparent;
		box-shadow: none;
	}
	ul.tabs.light li{
		color: #000;
		border-right:1px solid #dbdbdb;
		background-color:#f0f0f0;
	}
	ul.tabs.light li:hover,
	ul.tabs.light li.current,
	.tab-content.light,
	.menu.light .form-section{
		background-color:#f9f9f9;
	}
	ul.tabs.light li{
		border-bottom:1px solid rgba(120,120,120,.1);;
	}
	.menu.light .form-section{
		background-color:#fff;
	}


	/* #Parallax background menu image on mobile
	================================================== */

	#menu-wrap .parallax-home{
		display:none;
	}


	/* #Menu background is not trasparent mobile fix
	================================================== */

	.menu-back.menu-have-back-color{
		background: rgba(0,0,0,1);
	}
	.cbp-af-header.menu-have-back-color.cbp-af-header-shrink {
		background: rgba(0,0,0,1);
	}
	.menu-back.light.menu-have-back-color{
		background: rgba(245,245,245,1);
	}
	.cbp-af-header.light.menu-have-back-color.cbp-af-header-shrink {
		background: rgba(245,245,245,1);
	}


	/* #Icon on left side for menu on mobile
	================================================== */

	.menu.move-for-left .menu-mobile:after {
		float: left;
	}


	/* #Mobile fix for menu center and logo top
	================================================== */

	.menu.menu-center-logo-top {
		margin:0;
	}
	.menu.menu-center-logo-top > ul {
		float:none;
		margin:0;
		width:100%;
		text-align:left;
	}
	.menu.menu-center-logo-top > ul > li {
		float:left;
		margin: 0;
		display:block;
	}
	.cbp-af-header.cbp-af-header-shrink .logo.menu-center-logo-top{
		top:34px;
		left:0;
		transform: translateX(0);
	}
	.logo.menu-center-logo-top{
		top:34px;
		left:0;
		transform: translateX(0);
	}
	.menu-container.menu-center-logo-top{
		padding-top:0;
	}
	.cbp-af-header.cbp-af-header-shrink .menu-container.menu-center-logo-top {
		padding-top:0;
	}


}

@media only screen and (max-width: 1100px) {
.portfolio-box-1{
	width:calc(50% - 30px);
}
.blog-box{
	width:calc(50% - 30px);
}

.page-center-wrap .home-big-text.other-font{
	 font-size: 52px;
   line-height: 50px;
   margin-bottom: 8px;
}
.page-center-wrap .home-big-text.other-font.font-left{
	font-size: 40px;
	line-height:55px;
}
}

@media only screen and (max-width: 767px) {
.portfolio-box-1{
	width: 100%;
}
.blog-box{
	width:calc(100% - 30px);
}
}

@media only screen and (max-width: 600px) {

.portfolio-box-1.half-width{
	width:calc(100% - 30px);
}

.page-center-wrap .home-big-text{
	font-size: 55px;
	line-height:90px;
}
.page-center-wrap .home-small-text{
	font-size: 14px;
	line-height:18px;
}
.home-text h1{
	font-size: 26px;
	line-height:38px;
}
.home-text h1 span.cross-out{
	font-size: 18px;
	line-height:38px;
}
.case-study-wrapper li a {
	font-size:20px;
	line-height:18px;
	letter-spacing:1px;
}
.case-study-wrapper li a span {
	font-size:12px;
	letter-spacing:1px;
}
}

@media only screen and (min-width: 1320px) {
	.cta-box h4 {font-size: 24px;}
}
@media only screen and (min-width: 1200px) and (max-width: 1319px) {
}

@media only screen and (min-width: 960px) and (max-width: 1199px) {
	.cta-box {font-size: 1.25em;}
}

@media only screen and (min-width: 768px) and (max-width: 959px) {
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
	.cta-box {font-size: 1.15em; padding: 25px;}
	.social-footer{
		float:left;
	}
	.comment img{
		display:none;
	}
	.comment .time{
		padding-left:0;
		padding-top:0;
	}
	.comment h6:before{
		left:0;
	}
	.comment h6,
	.comment p{
		padding-left:0;
	}
	.comment.subcomment{
		width:calc(100% - 30px);
		margin-left:30px;
	}
}

@media only screen and (min-width: 320px) and (max-width: 479px) {
.social-footer{
	float:left;
}
.comment img{
	display:none;
}
.comment .time{
	padding-left:0;
	padding-top:0;
}
.comment h6:before{
	left:0;
}
.comment h6,
.comment p{
	padding-left:0;
}
.comment.subcomment{
	width:calc(100% - 30px);
	margin-left:30px;
}
}

@media only screen and (max-width: 319px) {
.social-footer{
	float:left;
}
.comment img{
	display:none;
}
.comment .time{
	padding-left:0;
	padding-top:0;
}
.comment h6:before{
	left:0;
}
.comment h6,
.comment p{
	padding-left:0;
}
.comment.subcomment{
	width:calc(100% - 30px);
	margin-left:30px;
}
}

@font-face {
    font-family: 'Speck-Display';
    src: url('font/Speck-Display.eot');
    src: url('font/Speck-Display.eot?#iefix') format('embedded-opentype'),
         url('font/Speck-Display.woff') format('woff'),
         url('font/Speck-Display.ttf') format('truetype'),
         url('font/Speck-Display.svg') format('svg');
    font-weight: normal;
    font-style: normal;
}
