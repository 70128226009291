.parallax-home {
	background: url('') repeat fixed;
	background-size: cover;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 1;
	width:100%;
}
@media only screen and (min-width: 1930px) { .parallax-home { background-size: cover; } }
 @media only screen and (min-device-width : 768px) and (max-device-width : 1200px) and (orientation : landscape) {
	.parallax-home { background-size: cover; background-attachment: scroll; background-position: top center!important; }
}
@media only screen and (max-width: 1400px) {
	.parallax-home { background-size: cover; background-attachment: scroll; background-position: top center!important; }
}

.parallax-project {
	background-size: cover !important;
	// background-position-y: -90px !important;
	// opacity: .87;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 1;
	// width:100%;
}
// body#contactpage .parallax-project {opacity: .75;}
body#candidatepage .parallax-project {
	background: url('https://res.cloudinary.com/infin80/image/upload/f_auto,dpr_auto,q_auto,w_1350,c_fill/epc/piping-cables-industry-factory-executive-staffing.jpg') no-repeat center fixed;
}
body#clientpage .parallax-project {
	background: url('https://res.cloudinary.com/infin80/image/upload/f_auto,dpr_auto,q_auto,w_1350,c_fill/epc/piping-cables-industry-factory-executive-placement.jpg') no-repeat center fixed;
}
body#knowledgepage .parallax-project {
	background: url('https://res.cloudinary.com/infin80/image/upload/f_auto,dpr_auto,q_auto,w_1350,c_fill/epc/piping-cables-industry-factory-executive-info.jpg') no-repeat center fixed;
}
body#contactpage .parallax-project {
	background: url('https://res.cloudinary.com/infin80/image/upload/f_auto,dpr_auto,q_auto:low,w_1350,c_fill/epc/civil-engineer-cityscape.jpg') no-repeat center fixed;
}
body#errorpage .parallax-project {
	background: url('https://res.cloudinary.com/infin80/image/upload/f_auto,dpr_auto,q_auto:low,w_1000/website/stop-secret-service-hault-404-page-error.jpg') no-repeat right fixed;
	background-size: contain !important;
}

@media only screen and (min-width: 1930px) { .parallax-project { background-size: cover; } }
 @media only screen and (min-device-width : 768px) and (max-device-width : 1200px) and (orientation : landscape) {
	.parallax-project { background-size: cover; background-attachment: scroll; background-position: top center!important; }
}
@media only screen and (max-width: 1400px) {
	.parallax-project { background-size: cover; background-attachment: scroll; background-position: top center!important; }
}

.parallax-about {
	background: url('../images/about.jpg') repeat fixed;
	background-size: cover;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 1;
	width:100%;
}
@media only screen and (min-width: 1930px) { .parallax-about { background-size: cover; } }
 @media only screen and (min-device-width : 768px) and (max-device-width : 1200px) and (orientation : landscape) {
	.parallax-about { background-size: cover; background-attachment: scroll; background-position: top center!important; }
}
@media only screen and (max-width: 1400px) {
	.parallax-about { background-size: cover; background-attachment: scroll; background-position: top center!important; }
}

.parallax-about-pattern {
	background: url('../images/seamless-bkgrd-lt-grey-paper.jpg') repeat fixed;
	// background-size: cover;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 1;
	width:100%;
}
@media only screen and (min-width: 1930px) { .parallax-about-pattern { background-size: cover; } }
 @media only screen and (min-device-width : 768px) and (max-device-width : 1200px) and (orientation : landscape) {
	.parallax-about-pattern { background-size: cover; background-attachment: scroll; background-position: top center!important; }
}
@media only screen and (max-width: 1400px) {
	.parallax-about-pattern { background-size: cover; background-attachment: scroll; background-position: top center!important; }
}

.parallax-blog {
	background: url('../images/blog.jpg') repeat fixed;
	background-size: cover;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 1;
	width:100%;
}
@media only screen and (min-width: 1930px) { .parallax-blog { background-size: cover; } }
 @media only screen and (min-device-width : 768px) and (max-device-width : 1200px) and (orientation : landscape) {
	.parallax-blog { background-size: cover; background-attachment: scroll; background-position: top center!important; }
}
@media only screen and (max-width: 1400px) {
	.parallax-blog { background-size: cover; background-attachment: scroll; background-position: top center!important; }
}
