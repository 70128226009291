#projects-grid{
	position:relative;
	margin:0 auto;
	width:calc(100% - 50px);
	max-width:calc(1320px - 20px);
	overflow:hidden;
}
#projects-grid:after {
  content: '';
  display: block;
  clear: both;
}
.portfolio-box-1{
	position:relative;
	float:left;
	width:calc(33.3333333333% - 30px);
	display:inline-block;
	box-sizing: border-box;
	overflow:hidden;
	margin-left:14px;
	margin-right:15px;
	margin-top:15px;
	margin-bottom:15px;
	transform: translate3d(1px,0,0);
}
.half-width{
	width:calc(50% - 30px);
}
.full-width{
	width:calc(100% - 30px);
}
.portfolio-box-1 .work-title{
	position:relative;
	display:inline-block;
	font-family: var(--ff-head);
	letter-spacing: -.75px;
	font-size: 23px;
	line-height: 26px;
	text-align: left;
	color:var(--dark);
	padding-bottom:5px;
    transition: all 300ms linear;
}
.portfolio-box-1 .work-title:before{
	position:absolute;
	content:'';
	left:-105%;
	width:100%;
	height:2px;
	bottom:0;
	z-index:2;
    transition: all 300ms linear;
}
.portfolio-box-1:hover .work-title:before{
	left:0;
}
.portfolio-box-1 .work-subtitle{
	position:relative;
	font-size: 13px;
	line-height: 15px;
	letter-spacing: 0.5px;
	font-family: var(--ff-sans);
	color:var(--grey-3);
	text-align: left;
	display:inline-block;
    text-transform: lowercase;
}
.portfolio-box-1 img{
	width:100%;
	display:block;
	padding-bottom: 12px;
}
.portfolio-box-1 .work-subtitle a {color: var(--grey-3); padding: 0 3px;}
.portfolio-box-1 .work-subtitle a:hover {
  background-color: var(--primary);
  color: #fff;
  transition: all 0.2s ease-out;
}
#portfolio-filter {
	position:relative;
	text-align: center !important;
	width:100%;
	margin-bottom:20px;
}
#portfolio-filter.left-filter {
	text-align: left;
	margin-bottom:0;
}
#filter {
	position:relative;
	display:inline-block;
	text-align: center;
	border-radius:30px;
}
#filter li {
	display: inline-block;
	text-align: center;
}
#filter:after {
  content: '';
  display: block;
  clear: both;
}
#filter li a {
	position: relative;
	display: inline-block;
	letter-spacing:1px;
	padding:6px 14px;
	box-sizing: border-box;
	font-size: 13px;
	line-height: 12px;
	text-align: center;
	outline: none;
	color: var(--dark);
	border-radius:30px;
	border:2px solid transparent;
	font-family: var(--ff-sans);
	text-decoration: none;
    transition: all 300ms linear;
}
#filter li .current {
}
#filter li a:hover {
}
.isotope-item {
    z-index: 2;
}
.isotope-hidden.isotope-item {
    pointer-events: none;
    z-index: 1;
}
.isotope, .isotope .isotope-item {
            transition-duration: 0.6s;
}
.isotope {
            transition-property: height, width;
}
.isotope .isotope-item {
            transition-property:         transform, opacity;
}

.portfolio-text p{
	text-align:left;
	margin-bottom:15px;
}
.portfolio-text p:last-child{
	margin-bottom:0;
}

.left-projects, .center-projects{
	position:relative;
	display:inline-block;
	float:left;
	text-align: left;
	color: var(--dark);
	font-family: var(--ff-head);
	font-weight:400;
	font-size: 24px;
	line-height: 24px;
	overflow:hidden;
    transition: all 300ms linear;
}
.center-projects {
	display:block;
	text-align: center;
	margin: 0 auto;
	float: none;
}
.center-projects:hover {color: var(--primary);}
.left-projects:before{
	position:absolute;
	content:'';
	background-image:url('../images/left-arrow.png');
	background-repeat: no-repeat;
	background-size: 35px 10px;
	background-position:center center;
	z-index:2;
	width:35px;
	height:10px;
	left:-35px;
	top:7px;
    transition: all 300ms linear;
}
.left-projects:hover:before{
	left:0;
    -webkit-transition-delay: 200ms;
    transition-delay: 200ms;
}
.left-projects:hover{
	padding-left:55px;
}
.right-projects{
	position:relative;
	display:inline-block;
	float:right;
	text-align: right;
	color: var(--dark);
	font-family: var(--ff-head);
	font-weight:400;
	font-size: 24px;
	line-height: 24px;
	overflow:hidden;
    transition: all 300ms linear;
}
.right-projects:hover{
	padding-right:55px;
}
.right-projects:before{
	position:absolute;
	content:'';
	background-image:url('../images/right-arrow.png');
	background-repeat: no-repeat;
	background-size: 35px 10px;
	background-position:center center;
	z-index:2;
	width:35px;
	height:10px;
	right:-35px;
	top:7px;
    transition: all 300ms linear;
}
.right-projects:hover:before{
	right:0;
    -webkit-transition-delay: 200ms;
    transition-delay: 200ms;
}

.link-scaledown a::before {
	position: absolute;
	top: 0;
	left: 0;
	z-index: -1;
	width: 100%;
	height: 100%;
	border-radius: 5px;
	background-color: rgba(255,255,255,1);
	content: '';
	opacity: 0;
	-webkit-transition: -webkit-transform 0.2s, opacity 0.3s, background-color 0.2s;
	transition: transform 0.2s, opacity 0.2s, background-color 0.2s;
	-webkit-transform: scale(1.1);
	transform: scale(1.1);
}
