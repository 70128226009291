.post-wrapper{
	position:relative;
	width: 100%;
}
.post-wrapper img{
	width:100%;
	display:block;
	margin-bottom:25px;
}
.post-wrapper h6{
	text-align:left;
}
.post-wrapper p.larger{
	text-align:left;
	font-size: 18px;
	line-height:24px;
	font-style: italic;
}
.post-wrapper p{
	text-align:left;
	padding-bottom:25px;
}
#owl-blog-slider{
	position:relative;
	width:100%;
	margin:0 auto;
	text-align:center;
}
#owl-blog-slider .item{
	position:relative;
	width:100%;
}
#owl-blog-slider .item img{
	width:100%;
	display:block;
	height:auto;
}
#owl-blog-slider.owl-theme .owl-controls{
	position:absolute;
	left:0;
	text-align: center;
	bottom:30px;
	z-index:100;
	width:100%;
	z-index:20;
}
#owl-blog-slider.owl-theme .owl-controls .owl-page span{
	background-color:#000;
	border-radius:50%;
	width:6px;
	height:6px;
	margin-bottom:10px;
    transition: all 300ms linear;
}
#owl-blog-slider.owl-theme .owl-controls .owl-page span:hover{
}
#owl-blog-slider.owl-theme .owl-controls .owl-page.active span {
}

.post-wrapper blockquote{
	font-size: 18px;
	line-height: 25px;
	font-style: italic;
	padding-top:0;
	padding-bottom:0;
	color: #777;
	padding-left:40px;
	margin-top:15px;
	margin-bottom:40px;
}
.post-wrapper img.half-post-image-left{
	width:50%;
	display:block;
	float:left;
	margin-right:25px;
	margin-bottom:25px;
}
.image-title-left{
	font-size: 14px;
	line-height: 14px;
	font-style: italic;
	width:100%;
	text-align:left;
	display:block;
	float:left;
	font-family: var(--ff-sans);
	color: #999;
	margin-bottom:10px;
}
.post-wrapper img.half-post-image-right{
	width:50%;
	display:block;
	float:right;
	margin-left:25px;
	margin-bottom:25px;
}
.image-title-right{
	font-size: 14px;
	line-height: 14px;
	font-style: italic;
	width:100%;
	text-align: right;
	display:block;
	float:right;
	font-family: var(--ff-sans);
	color: #999;
	margin-bottom:10px;
}
.num-of-com{
	position:relative;
	display:block;
	width:100%;
	color: var(--dark);
	font-family: var(--ff-head);
	font-weight:400;
	text-align:left;
	font-size: 15px;
	line-height: 20px;
	letter-spacing:1px;
	padding-top:40px;
}
.num-of-com span{
	padding-right:10px;
	font-size: 55px;
	line-height: 20px;
	font-style: italic;
}
.comm-link{
	position:relative;
	display:inline-block;
	font-family: var(--ff-sans);
	font-weight:400;
	text-align:left;
	font-size: 17px;
	line-height: 20px;
	font-style: italic;
	margin-top:10px;
    transition: all 300ms linear;
}
.comm-link:hover{
	opacity:.6;
}


.sidebar{
	position:relative;
	width: 100%;
}
.sidebar-sep{
	position:relative;
	width: 100%;
	padding-bottom:70px;
}
.sidebar-list-one{
	position:relative;
	width: 100%;
	display:block;
}
.sidebar-list-one li{
	position:relative;
	display:block;
	padding-left:45px;
	margin-bottom:20px;
}
.sidebar-list-one li a{
	font-family: var(--ff-sans);
	font-size: 15px;
	line-height:22px;
	font-weight:500;
	color:var(--dark);
	text-align:left;
	letter-spacing:1px;
    transition: all 300ms linear;
}
.sidebar-list-one li a:hover{
	padding-left:5px;
}
.sidebar-list-one li:last-child{
	margin-bottom:0;
}
.sidebar-list-one li:before{
	position:absolute;
	padding:0;
	margin:0;
	content:'';
	width:4px;
	height:4px;
	border-radius:50%;
	left:20px;
	top:8px;
	z-index:2;
}
.sidebar-list-two{
	position:relative;
	width: 100%;
	display:block;
}
.sidebar-list-two li{
	position:relative;
	display:inline-block;
	margin-right:5px;
	margin-bottom:14px;
}
.sidebar-list-two li a{
	font-family: var(--ff-sans);
	font-size: 13px;
	line-height:13px;
	font-weight:500;
	color:#999;
	border-radius:20px;
	border:2px solid #999;
	padding:5px 15px;
	text-align:center;
	letter-spacing:1px;
    transition: all 300ms linear;
}
.sidebar-list-two li a:hover{
	color:#fff;
}

.sidebar-search{
	position:relative;
	width: 100%;
	display:block;
}
.sidebar-search input{
	position:relative;
	width:calc(100% - 40px);
	padding-left:20px;
	padding-right:20px;
	font-family: var(--ff-unique);
	font-weight:400;
	letter-spacing:1px;
	font-size: 13px;
	line-height:24px;
	padding-bottom: 15px;
	background:transparent;
	border:none;
	color: #737373;
	-webkit-transition: border-bottom 0.3s, color 0.3s;
	transition: border-bottom 0.3s, color 0.3s;
}
.sidebar-search input {
	border-bottom:1px solid rgba(100,100,100,.2);
}
.sidebar-search input:focus {
    outline: none !important;
}
.sidebar-search input:focus {
    outline: none !important;
}
.sidebar-search input:-ms-input-placeholder  {
	font-size: 11px;
	letter-spacing:1px;
	color:#414141;
}
.sidebar-search input::-moz-placeholder  {
	font-size: 11px;
	letter-spacing:1px;
	color:#414141;
}
.sidebar-search input:-moz-placeholder  {
	font-size: 11px;
	letter-spacing:1px;
	color:#414141;
}
.sidebar-search input::-webkit-input-placeholder  {
	font-size: 11px;
	letter-spacing:1px;
	color:#414141;
}
.sidebar-search button{
	position:absolute;
	font-family: 'FontAwesome';
	width:30px;
	right:0;
	top:0;
	height:30px;
	font-size: 12px;
	line-height:30px;
	text-align:center;
	border-radius:50%;
	padding: 0;
	margin:0;
	border:none;
	color: #fff;
	z-index:2;
    transition: all 300ms linear;
}
.sidebar-search button:hover{
	opacity:.6;
}
.sidebar-search button:focus,
.sidebar-search button:active {
	border:none;
	outline:none;
}




.comment{
	position:relative;
	display:block;
	width:100%;
	padding-bottom:80px;
}
.comment.subcomment{
	width:calc(100% - 70px);
	margin-left:70px;
}
.comment img{
	position:absolute;
	display:block;
	width:80px;
	height:80px;
	top:0;
	left:0;
	border-radius:50%;
}
.comment .time{
	position:relative;
	padding-left:110px;
	font-style: italic;
	padding-top:15px;
	font-family: var(--ff-sans);
	font-size: 13px;
	line-height:13px;
	padding-bottom:3px;
	color:#444;
}
.comment h6:before{
	left:110px;
}
.comment h6,
.comment p{
	padding-left:110px;
}
.comment a{
	position:relative;
	font-family: var(--ff-unique);
	font-size:12px;
	line-height:12px;
	font-weight:600;
	display:block;
	float:right;
	text-align:right;
	margin-top:40px;
	opacity:.6;
    transition: all 300ms linear;
}
.comment a:hover{
	opacity:1;
}

