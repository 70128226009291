.footer .footer-flex {
	display: flex;
	justify-content: space-around;
}
.footer p{
	text-align: left;
	color: var(--secondary);
	padding-bottom:5px;
}

.logo-text-footer{
	position:relative;
    transition: all 300ms linear;
}
.logo-text-footer:hover{
	opacity:0.75
}

.social-footer{
	position:relative;
	display:block;
}
.list-social-footer li {
	width:45px;
  display: inline-block;
	margin-bottom:12px;
    transition: all 300ms linear;
}
.list-social-footer li, div.icon-footer i:first-child, div.icon-footer i {margin-left: 0;}
.list-social-footer li.icon-footer a {
	font-size: 18px;
	color: var(--secondary);
    transition: all 100ms linear;
}
.icon-footer p {white-space: nowrap}
.icon-footer a {margin-top:12px;}

/* #Scroll back to top
================================================== */

.scroll-to-top {
	position: fixed;
	cursor: pointer;
	top: 120px;
	opacity:.5;
	right: 40px;
	width:30px;
	height: 30px;
	display: none;
	z-index: 9999;
	background-image:url('../images/up-arrow.png');
	background-position:center center;
	background-repeat: no-repeat;
	background-size:30px 30px;
    transition: all 300ms linear;
}
.scroll-to-top:hover {
	opacity:1;
	top: 110px;
}
p.rotate-phone {
	position: fixed;
	top: 208px;
  right: -6px;
	transform: rotate(90deg);
	z-index: 5;
}
p.rotate-phone a {background: none;}
p.rotate-phone a:hover {color: var(--primary);}


/* #Media Queries
================================================== */

@media only screen and (max-width: 1200px) {
	.page-center-wrap .home-big-text{
		font-size: 70px;
		line-height:110px;
	}
	.page-center-wrap .home-small-text{
		font-size: 15px;
		line-height:21px;
	}
}
@media only screen and (max-width: 959px) {
	.list-social-footer li {width: 25px;}
	.logo-text-footer img {width: 145px; height:auto;}
}
@media only screen and (max-width: 767px) {
	.footer .footer-flex {display: block;}
	.footer .footer-flex .column {margin-bottom: 15px;}
	.logo-text-footer img {width: initial;}
}


