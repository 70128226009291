h6{
	padding-bottom:30px;
	text-align:left;
	position:relative;
}
h6:before{
	position:absolute;
	content:'';
	bottom:20px;
	left:0;
	height:3px;
	width:35px;
}
.title-wrapper{
	position:relative;
	display:block;
	width:100%;
	margin-bottom:15px;
}
.title-wrapper .title-text-top{
	position:relative;
	display:inline-block;
	text-align: left;
	font-family: var(--ff-head);
	font-size: 30px;
}
.title-wrapper .title-text-bottom{
	position:relative;
	display:inline-block;
	text-align: left;
	max-width:550px;
	font-family: var(--ff-unique);
	font-size: 19px;
	line-height: 27px;
	padding-top:20px;
	color: var(--grey-2);
	letter-spacing:.1px;
	font-weight: bold;
}
.team-wrap{
	position:relative;
	width:100%;
	display:block;
}
.team-wrap img{
	width:100%;
	display:block;
	margin-bottom:15px;
}

.social-team{
	position:relative;
	display:block;
	float:left;
}
.list-social-team li {
    padding:0;
	margin:0;
    list-style: none;
	text-align:center;
	width:13px;
	height:13px;
    display: inline-block;
	cursor:pointer;
	margin-right:12px;
    transition: all 300ms linear;
}
.list-social-team li.icon-team a {
	font-family: 'FontAwesome';
	font-size: 13px;
	line-height:13px;
	width:20px;
	color:#999;
	opacity:1;
	margin:0;
	padding:0;
    transition: all 300ms linear;
}
#logos .container .columns, #logos .container .three.columns {margin-top: 5px !important;}
.partners{
	position: relative;
	width:100%;
	display:block;
	margin:0 auto;
	text-align:center;
	// padding-top: 5px;
	// padding-bottom: 5px;
	border-radius:4px;
	cursor:pointer;
}
.partners img{
  width: 70%;
  height: auto;
  display: block;
  margin: auto;
  text-align: center;
  opacity: .6;
  filter: grayscale(1);
	opacity:.6;
  transition: all 300ms linear;
}
.partners img:first-child{width: 80%;}
.partners:hover img{
	opacity:1;
	filter: grayscale(0);
}
