.blog-box{
	position:relative;
	float:left;
	width:calc(33.3333333333333% - 30px);
	display:inline-block;
	box-sizing: border-box;
	overflow:hidden;
	margin-left:14px;
	margin-right:15px;
	margin-top:15px;
	margin-bottom:15px;
	transform: translate3d(1px,0,0);
}
.content-in{
	position:relative;
	width:100%;
	padding-bottom:30px;
	background-color:#ecefef;
	transition: all 0.4s linear;
}
.content-in:hover {background-color: #f8f8f8;}
.content-in:hover img {transform: scale(1.07);}
.content-in img{
	width:100%;
	display:block;
}
.content-in h6{
	margin-top:30px;
	margin-left:30px;
	margin-right:30px;
    transition: all 300ms linear;
}
.content-in h6:hover{
	opacity:.9;
}
.content-in h6:before{
	background-color: var(--grey-2);
}
.content-in p{
	text-align: left;
	padding-bottom:25px;
	padding-left:30px;
	padding-right:30px;
}
.content-in .tag-link{
	position:relative;
	display:inline-block;
	text-align: left;
	margin-right:5px;
	font-family: var(--ff-unique);
	font-size: 13px;
	line-height:17px;
	letter-spacing:1px;
	font-weight:600;
	color: var(--grey-3);
    transition: all 300ms linear;
}
.content-in .tag-link.first{
	margin-left:30px;
}
.content-in .blog-link{
	position:relative;
	display:inline-block;
	text-align: left;
	margin-top:20px;
	font-family: var(--ff-sans);
	font-size: 14px;
	line-height:17px;
	font-weight:700;
	margin-left:30px;
    transition: all 300ms linear;
}
.content-in .blog-link:hover{
	letter-spacing:1px;
}
/* Video Hover Image */

.video-wrapper, figure.vimeo, figure.youtube {
	margin:0;
	position: relative;
	padding-bottom: 56.25%;
	padding-top: 0;
	height: 0;
	overflow: hidden;
	margin-bottom:25px;
}
.video-wrapper iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
figure.youtube a img, figure.vimeo a img {
	position: absolute;
	top: 0;
	left: 0;
	width: auto;
	height: 100%;
	max-width:none;
}
figure.vimeo a:after, figure.youtube a:after {
	content:"";
	width:80px;
	height:80px;
	background:#fff;
	z-index:9;
	position:absolute;
	top:50%;
	left:50%;
	margin:-40px 0 0 -40px;
	border-radius:50%;
	box-shadow: 0px 0px 20px 10px rgba(0,0,0,0.1);
    transition: all 300ms linear;
}
figure.vimeo:hover a:after, figure.youtube:hover a:after {
	background:var(--dark);

	box-shadow: 0px 0px 30px 10px rgba(0,0,0,0.2);
}
figure.vimeo a:before, figure.youtube a:before {
	content:"";
	width: 0;
	height: 0;
	border-top: 7px solid transparent;
	border-bottom: 7px solid transparent;
	border-left: 8px solid #414141;
	z-index:10;
	position:absolute;
	top:50%;
	left:50%;
	margin-left:-3px;
	margin-top:-7px;
	display:block;
    transition: all 300ms linear;
}
figure.vimeo:hover a:before, figure.youtube:hover a:before {
	border-left: 10px solid #fff;
}
figure.vimeo a:hover img, figure.youtube a:hover img {
	transition: all 0.6s;
	transform: scale(1.03) rotate(1deg);
}
figure.vimeo a img, figure.youtube a img {
  transition: all 300ms linear;
	transform: scale(1);
}
