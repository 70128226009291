form {
	width: 100%;
	font-family: var(--ff-unique);
	font-size: 11px;
	line-height:14px;
	color: var(--dark);
	margin:0 auto;
}
form label {
	display: block;
	font-family: var(--ff-unique);
	font-size: 14px;
	line-height:30px;
}
form input{
	position:relative;
	width:calc(100% - 40px);
	padding-left:20px;
	padding-right:20px;
	font-family: var(--ff-unique);
	font-weight:400;
	letter-spacing:1px;
	font-size: 14px;
	line-height:24px;
	padding-bottom: 15px;
	background:transparent;
	border:none;
	color: var(--primary);
	-webkit-transition: border-bottom 0.3s, color 0.3s;
	transition: border-bottom 0.3s, color 0.3s;
}
form textarea {
	width:calc(100% - 40px);
	padding-left:20px;
	padding-right:20px;
	font-family: var(--ff-unique);
	font-weight:400;
	letter-spacing:1px;
	font-size: 13px;
	line-height:24px;
	background:transparent;
	height:100px;
	border:none;
	color: var(--primary);
	-webkit-transition: border-bottom 0.3s, color 0.3s;
	transition: border-bottom 0.3s, color 0.3s;
}
form textarea, form input {
	border-bottom:1px solid rgba(100,100,100,.2);
}
.error {
	font: 11px/22px var(--ff-unique);
	text-transform:uppercase;
	letter-spacing:1px;
	display: none;
	color:#bd0b0f;
}
#ajaxsuccess {
	background: #ecf0f0;
	color: var(--primary);
	font: 20px/60px var(--ff-unique);
	height:60px;
	display: none;
	padding-left:20px;
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	margin-top: 30px;
}
.text-align-left{
	text-align: left;
	padding-top:10px;
	margin-left:25px;
	margin-right:25px;
 }
form textarea:active,
form input:active {
	color: var(--primary) !important;
	font-weight: 700;
;
}
form textarea:active,
form input:hover {
	color: var(--primary);
}
form textarea:focus,
form input:focus {
    outline: none !important;
}
form input:-ms-input-placeholder  {
	font-size: 11px;
	text-transform:uppercase;
	letter-spacing:2px;
	color: var(--secondary);
}
form input::-moz-placeholder  {
	font-size: 11px;
	text-transform:uppercase;
	letter-spacing:2px;
	color: var(--secondary);
}
form input:-moz-placeholder  {
	font-size: 11px;
	text-transform:uppercase;
	letter-spacing:2px;
	color: var(--secondary);
}
form input::-webkit-input-placeholder  {
	font-size: 11px;
	text-transform:uppercase;
	letter-spacing:2px;
	color: var(--secondary);
}
form textarea:-ms-input-placeholder  {
	font-size: 11px;
	text-transform:uppercase;
	letter-spacing:2px;
	color: var(--secondary);
}
form textarea::-moz-placeholder  {
	font-size: 11px;
	text-transform:uppercase;
	letter-spacing:2px;
	color: var(--secondary);
}
form textarea:-moz-placeholder  {
	font-size: 11px;
	text-transform:uppercase;
	letter-spacing:2px;
	color: var(--secondary);
}
form textarea::-webkit-input-placeholder  {
	font-size: 11px;
	text-transform:uppercase;
	letter-spacing:2px;
	color: var(--secondary);
}
form button {
	background: var(--secondary);
	color:#fff;
	font-size:13px;
	padding: 13px 25px;
	max-width:150px;
}
form button:hover {
	color:#fff;
}
form button:focus,
form button:active {
	border:none;
	outline:none;
}


.button-effect {
	// min-width: 150px;
	// max-width: 200px;
	display: block;
	border: none;
	background: none;
	color: inherit;
	vertical-align: middle;
	cursor:pointer;
	position: relative;
	float:left;
	z-index: 1;
	-webkit-backface-visibility: hidden;
	-moz-osx-font-smoothing: grayscale;
}
.button-effect:focus {
	outline: none;
}
.button-effect > span {
	vertical-align: middle;
}
.button--moema {
	padding: 14px 26px;
	border-radius: 1px;
	text-align:center;
	background: var(--secondary);
	letter-spacing:2px;
	color: #fff;
	-webkit-transition: background-color 0.3s, color 0.3s;
	transition: background-color 0.3s, color 0.3s;
}
.button--moema::before {
	content: '';
	position: absolute;
	top: -20px;
	left: -20px;
	bottom: -20px;
	right: -20px;
	background: inherit;
	border-radius: 3px;
	z-index: -1;
	opacity: 0.4;
	-webkit-transform: scale3d(0.8, 0.5, 1);
	transform: scale3d(0.8, 0.5, 1);
}
.button--moema:hover {
	-webkit-transition: background-color 0.3s 0s, color 0.3s 0s;
	transition: background-color 0.3s 0s, color 0.3s 0s;
	color:#fff;
	-webkit-animation: anim-moema-1 0.6s forwards;
	animation: anim-moema-1 0.6s forwards;
}
.button--moema:hover::before {
	-webkit-animation: anim-moema-2 0.6s 0.5s forwards;
	animation: anim-moema-2 0.6s 0.5s forwards;
}
@-webkit-keyframes anim-moema-1 {
	60% {
		-webkit-transform: scale3d(0.8, 0.8, 1);
		transform: scale3d(0.8, 0.8, 1);
	}
	85% {
		-webkit-transform: scale3d(1.2, 1.2, 1);
		transform: scale3d(1.2, 1.2, 1);
	}
	100% {
		-webkit-transform: scale3d(1, 1, 1);
		transform: scale3d(1, 1, 1);
	}
}
@keyframes anim-moema-1 {
	60% {
		-webkit-transform: scale3d(0.8, 0.8, 1);
		transform: scale3d(0.8, 0.8, 1);
	}
	85% {
		-webkit-transform: scale3d(1.2, 1.2, 1);
		transform: scale3d(1.2, 1.2, 1);
	}
	100% {
		-webkit-transform: scale3d(1, 1, 1);
		transform: scale3d(1, 1, 1);
	}
}
@-webkit-keyframes anim-moema-2 {
	to {
		opacity: 0;
		-webkit-transform: scale3d(1, 1, 1);
		transform: scale3d(1, 1, 1);
	}
}
@keyframes anim-moema-2 {
	to {
		opacity: 0;
		-webkit-transform: scale3d(1, 1, 1);
		transform: scale3d(1, 1, 1);
	}
}

/* #Google map
================================================== */

#google-container {
	position: relative;
	width: 100%;
    height:70vh;
}
#cd-google-map {
	position: relative;
}
#cd-zoom-in, #cd-zoom-out {
	height: 32px;
	width: 32px;
	cursor: pointer;
	margin-left: 10px;
	background-repeat: no-repeat;
	background-size: 32px 64px;
	opacity:.7;
	background-image: url("../images/cd-icon-controller.svg");
	z-index:3;
    transition: all 300ms linear;
}
#cd-zoom-in:hover, #cd-zoom-out:hover {
	opacity:1;
}
.no-touch #cd-zoom-in:hover, .no-touch #cd-zoom-out:hover {
	opacity:1;
}
#cd-zoom-in:hover, #cd-zoom-out:hover {
	opacity:1;
}
@media only screen and (min-width: 768px) {
  #cd-zoom-in, #cd-zoom-out {
    margin-left: 50px;
  }
}
#cd-zoom-in {
	background-position: 50% 0;
	margin-top: 50px;
	margin-bottom: 1px;
	z-index:3;
}
#cd-zoom-out {
	background-position: 50% -32px;
	z-index:3;
}

/* #Tooltip
================================================== */

	.tipper { height: 1px; left: -99999px; position: absolute; pointer-events: none; top: -99999px; width: 1px;z-index:100000; }
	.tipper .tipper-content { background: transparent; font-weight:400;letter-spacing:1px; border-radius: 3px; display: block; float: left; font-family: var(--ff-head); font-size: 20px; margin: 0; padding: 6px 20px; position: relative; white-space: nowrap; }
	.tipper .tipper-caret { display: block; height: 11px;  position: absolute; width: 100%; }

	.tipper.right .tipper-content {  }
	.tipper.right .tipper-caret { background-position: left center; left: -5px; top: 0; }

	.tipper.left .tipper-content { }
	.tipper.left .tipper-caret { background-position: right center; right: -5px; top: 0; }

	.tipper.top .tipper-caret,
	.tipper.bottom .tipper-caret { display: block; float: none; height: 5px;  width: 12px;margin-left:-6px; }

	.tipper.top .tipper-content { }
	.tipper.top .tipper-caret { background-position: center bottom; bottom: -5px; left: 0; text-align:center;}

	.tipper.bottom .tipper-content {  }
	.tipper.bottom .tipper-caret { background-position: center top; top: -5px; left: 0; }
