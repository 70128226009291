#menu-wrap{
  position: fixed;
	width: 100%;
	z-index: 5000;
	top: 0;
}

#menu-wrap .container .columns  {
	margin-top: 0;
	margin-bottom: 0;
}
.menu-back{
	background: rgba(0,0,0,0);
	transition : all 0.3s ease-out;
}
.cbp-af-header {
	transition : all 0.3s ease-out;
}

.cbp-af-header.cbp-af-header-shrink {
	background: rgba(0,0,0,.9);
	transition : all 0.3s ease-out;
}
.cbp-af-header.cbp-af-header-shrink .logo{
	height:50px;
	top:15px;
	transition : all 0.3s ease-out;
}
.cbp-af-header.cbp-af-header-shrink .logo img{
	height:50px;
	display:block;
}
.cbp-af-header.cbp-af-header-shrink .menu > ul > li a {
  padding: 30px 22px;
	transition : all 0.3s ease-out;
}
.cbp-af-header.cbp-af-header-shrink .menu > ul > li > ul > li a {
	padding:0;
	margin-left:10px;
	margin-right:10px;
	color: var(--grey-1);
	font-family: var(--ff-unique);
	font-weight:600;
	font-size: 10px;
	line-height:18px;
	letter-spacing:2px;
	text-transform:uppercase;
    width: calc(100% - 20px);
    display: block;
}
.cbp-af-header.cbp-af-header-shrink .menu > ul > li > ul > li a:hover {
	color:#fff;
}
.cbp-af-header.cbp-af-header-shrink .menu > ul > li > ul.normal-sub {
    width: 240px;
    left: auto;
    padding: 10px 0;
}
.cbp-af-header.cbp-af-header-shrink .menu > ul > li > ul.normal-sub > li {
    width: 100%;
}
.cbp-af-header.cbp-af-header-shrink .menu > ul > li > ul.normal-sub > li a {
    border: 0;
    padding: 1em 20px;
}
.cbp-af-header.cbp-af-header-shrink .menu > ul > li a.social {
    text-decoration: none;
    padding: 30px 8px;
    display: block;
	font-weight:normal;
	letter-spacing:0;
	color:#fff;
	display: inline-block;
	vertical-align: inherit;
	font: normal normal normal 12px/18px FontAwesome;
	text-rendering: auto;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	transition : all 0.3s ease-out;
}
.cbp-af-header.cbp-af-header-shrink .menu > ul > li a.social.first-icon {
    padding-left:50px;
}
.logo{
	position:absolute;
	height:41px;
	z-index:10000;
	top:50px;
	transition : all 0.3s ease-out;
}
.logo img{
	height:70px;
	width: auto;
	display:block;
	transition : all 0.3s ease-out;
}

.menu-mobile {
    display: none;
	padding-top:50px;
	padding-bottom:40px;
}
/* Step 1: Common Properties: All required to make icons render reliably */
.menu-mobile::before, .menu-dropdown-icon::before, .menu-dropdown-icon-sub::before {
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
}

/* Step 2: Reference Individual Icons */
.menu-mobile:before {
  content: "\f0c9";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
    // content: "\f394";
    // font-family: "Ionicons";
    font-size: 25px;
	height:40px;
	line-height:40px;
    padding: 0;
    float: right;
    position: relative;
    top: 0;
	color: var(--grey-4);
    transform: translateY(-50%);
}
.menu-dropdown-icon:before {
    content: "\f0fe";
    font-family: "Font Awesome 5 Free";
    display: none;
    cursor: pointer;
    float: right;
    padding: 8px 12px;
    background: var(--dark);
    color:  var(--grey-4);
	margin-top:5px;
}
.menu-dropdown-icon-sub:before {
    content: "\f0fe";
    font-family: "Font Awesome 5 Free";
    display: none;
    cursor: pointer;
    float: right;
	top:-2px;
    padding: 6px 10px;
    background: var(--dark);
    color: #fff;
}

.menu-container{
	position:relative;
	width:100%;
	max-width:1270px;
	margin:0 auto;
	z-index:10;
}
.menu  {
	position:relative;
	width:100%;
}
.menu img {
	width:100%;
	display:block;
}
.menu > ul {
    margin: 0 auto;
    width: auto;
	float:right;
    list-style: none;
    padding: 0;
    /* IF .menu position=relative -> ul = container width, ELSE ul = 100% width */
    box-sizing: border-box;
}

.menu > ul:before,
.menu > ul:after {
    content: "";
    display: table;
}

.menu > ul:after {
    clear: both;
}

.menu > ul > li {
    float: left;
    padding: 0;
    margin: 0;
    position: relative;
}
.menu>ul>li a.dropdown-toggle:after {
    content: "";
    border-top: .25em solid;
    border-right: .25em solid transparent;
    border-left: .25em solid transparent;
    // margin: 7px 0 0 3px;
    /* color: var(--primary); */
    position: relative;
    top: 12px;
    left: 2px;
}
.menu > ul > li a {
    text-decoration: none;
    padding: 50px 15px;
	padding-top:80px;
    display: block;
	font-family: var(--ff-unique);
	font-variant: lowercase;
	font-weight:600;
	font-size: 1em;
	line-height:18px;
	letter-spacing: 1.25px;
	color:#fff;
	transition : all 0.3s ease-out;
	text-shadow: 0 1px 0 rgba(250,250,250,.33);
}

.menu > ul > li a.social {
    text-decoration: none;
    padding: 50px 8px;
    display: block;
	font-weight:normal;
	letter-spacing:0;
	color:#fff;
	display: inline-block;
	vertical-align: inherit;
	font: normal normal normal 12px/18px FontAwesome;
	text-rendering: auto;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	transition : all 0.3s ease-out;
}
.menu > ul > li a.social.first-icon {
    padding-left:50px;
}
.menu > ul > li:hover > ul {
}
.menu > ul > li > ul {
    width: 100%;
    background: #111;
    padding: 30px 30px;
    position: absolute;
    z-index: -20;
    left: 0;
    margin: 0;
    list-style: none;
    box-sizing: border-box;
    display: block;
	visibility:hidden;
    opacity: 0;
	height:0;
    transition: all 0.2s ease-in-out;
}
.menu > ul > li > ul.active {
    opacity: 1;
    z-index: 99999;
	height:auto;
	visibility:visible;
	height:auto;
}

.menu > ul > li > ul:before,
.menu > ul > li > ul:after {
    content: "";
    display: table;
}

.menu > ul > li > ul:after {
    clear: both;
}

.menu > ul > li > ul > li {
    margin: 0;
    padding-bottom: 0;
    list-style: none;
    width: 25%;
    background: none;
    float: left;
}
.menu > ul > li > ul > li.three-col {
    width: 33.3333333333333%;
}
.menu > ul > li > ul > li.two-col {
    width: 50%;
}
.menu > ul > li > ul > li.full-width-sec{
	width:100%;
}
.menu > ul > li > ul > li.two-thirds-col{
	width:66.666666666666666%;
}

.menu > ul > li > ul > li p {
	padding:0;
    padding-bottom:10px;
	margin-left:10px;
	margin-right:10px;
	color:#fff;
	font-family: var(--ff-sans);
	font-weight:500;
	font-size: 12px;
	line-height:18px;
	letter-spacing:2px;
	text-transform:uppercase;
    width: calc(100% - 20px);
    display: block;
    border-bottom: 1px solid rgba(255,255,255,.2);
}
.menu > ul > li > ul > li p.case-sub {padding-bottom: 2px};
.menu > ul > li > ul > li a img {
	padding-bottom:15px;
    transition: all 300ms linear;
}
.menu > ul > li > ul > li a:hover img {
	opacity:.8;
}
.menu > ul > li > ul > li p span {
	vertical-align: super;
	font-size: 9px;
	line-height:9px;
	font-weight:500;
}
.menu > ul > li > ul > li a {
	padding:0;
	margin-left:11px;
	margin-right:10px;
  color: var(--secondary) !important;
	font-family: var(--ff-unique);
	font-weight:700;
	font-size: 10px;
	line-height:18px;
	letter-spacing:2px;
	text-transform:uppercase;
    width: calc(100% - 20px);
    display: block;
}
.menu > ul > li > ul > li a span {
	vertical-align: super;
	font-size: 9px;
	line-height:9px;
	font-weight:500;
}
.menu > ul > li > ul > li a:hover {
	color:#fff;
}

.menu > ul > li > ul > li > ul {
    display: block;
    padding: 0;
    margin: 10px 0 0;
    list-style: none;
    box-sizing: border-box;
}

.menu > ul > li > ul > li > ul:before,
.menu > ul > li > ul > li > ul:after {
    content: "";
    display: table;
}

.menu > ul > li > ul > li > ul:after {
    clear: both;
}

.menu > ul > li > ul > li > ul > li {
    float: left;
    width: 100%;
    padding: 7px 0;
    margin: 0;
}

.menu > ul > li > ul > li > ul > li a {
    border: 0;
}

.menu > ul > li > ul.normal-sub {
    width: 240px;
    left: auto;
    padding: 10px 0;
}
.menu > ul > li > ul.normal-sub p{
    margin-left:30px;
	margin-right:30px;
	margin-top:15px;
	margin-bottom:5px;
	width:calc(100% - 60px);
	font-size: 10px;
}
.menu.light > ul > li > ul > li p {color: var(--grey-2) !important;}
.menu > ul > li > ul.normal-sub li a.subtitled{
    font-size:10px;
	font-weight:normal;
    letter-spacing:4px;
    color: #fff;
    background-color: var(--primary);
}
.menu > ul > li > ul.normal-sub li a.subtitled small{
    letter-spacing:3px;
	 color: var(--grey-2); //#a9a9a9
	font-size: 11px;
	text-transform:none;
}

.menu > ul > li > ul.normal-sub > li {
	position:relative;
    width:100%;
}
.menu>ul>li>ul.normal-sub>li:hover {
   background-color: var(--primary);
}
.menu > ul > li > ul.normal-sub > li a {
    border: 0;
    padding: 1em 20px;
    text-shadow: none;
}
.menu > ul > li > ul.normal-sub > li:hover a {
   color: #fff;
   text-shadow: none;
}
.menu > ul > li > ul.normal-sub > li a span.go-left-icon {
	position:absolute;
	left:10px;
	top:14px;
	width:10px;
	height:10px;
	z-index:10;
	background-image:url('../images/arrow-white.svg');
	background-repeat:no-repeat;
	background-position:center center;
	background-size:10px 10px;
}
.menu > ul > li > ul.normal-sub > li a span.go-right-icon {
	position:absolute;
	right:10px;
	top:14px;
	width:10px;
	height:10px;
	z-index:10;
	background-image:url('../images/arrow-black.svg');
	background-repeat:no-repeat;
	background-position:center center;
	background-size:10px 10px;
    transform: rotate(180deg);
}
.menu > ul > li > ul.normal-sub > li ul.go-left {
    display: none;
	position:absolute;
	left:-240px;
	top:-20px;
	z-index:20;
    width: 240px;
    padding: 10px 20px;
    background: #111;
}
.menu > ul > li > ul.normal-sub > li ul.go-right {
    display: none;
	position:absolute;
	left:240px;
	top:-20px;
	z-index:20;
    width: 240px;
    padding: 10px 20px;
    background: #111;
}
.menu > ul > li > ul.normal-sub > li ul.go-left li,
.menu > ul > li > ul.normal-sub > li ul.go-right li {
    padding: 0;
}
.menu > ul > li > ul.normal-sub > li ul.go-left li a,
.menu > ul > li > ul.normal-sub > li ul.go-right li a {
    border: 0;
    padding: 1em 0;
}
.cbp-af-header.cbp-af-header-shrink .menu > ul > li > ul.normal-sub > li ul.go-left li a,
.cbp-af-header.cbp-af-header-shrink .menu > ul > li > ul.normal-sub > li ul.go-right li a {
    border: 0;
    padding: 1em 0;
}
.cbp-af-header.cbp-af-header-shrink .menu > ul > li > ul.normal-sub > li ul.go-left li,
.cbp-af-header.cbp-af-header-shrink .menu > ul > li > ul.normal-sub > li ul.go-right li {
    padding: 0;
}
.separator {
	clear: both;
	display: block;
	overflow: hidden;
	visibility: hidden;
	width: 100%;
	height: 0;
}
.separator-10 {
	clear: both;
	display: block;
	overflow: hidden;
	visibility: hidden;
	width: 100%;
	height:10px;
}
.separator-20 {
	height:20px;
	clear: both;
	display: block;
	overflow: hidden;
	visibility: hidden;
	width: 100%;
	height:20px;
}

/* Video Hover Image */
.video-section{
	position:relative;
	display: block;
	margin-bottom:15px;
	box-sizing: border-box;
	margin-left:10px;
	margin-right:10px;
}
iframe{
	display: block;
}

.menu a span.link-icon  {
	display: inline-block;
	vertical-align: inherit;
	padding-right:10px;
	font: normal normal normal 10px/18px FontAwesome;
	font-size: inherit;
	text-rendering: auto;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
