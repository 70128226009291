/* overpass-300italic - latin */
@font-face {
  font-family: 'Overpass';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url('../fonts/Overpass/overpass-v4-latin-300italic.eot'); /* IE9 Compat Modes */
  src: local('Overpass Light Italic'), local('Overpass-LightItalic'),
       url('../fonts/Overpass/overpass-v4-latin-300italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/Overpass/overpass-v4-latin-300italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/Overpass/overpass-v4-latin-300italic.woff') format('woff'), /* Modern Browsers */
       url('../fonts/Overpass/overpass-v4-latin-300italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/Overpass/overpass-v4-latin-300italic.svg#Overpass') format('svg'); /* Legacy iOS */
}
/* overpass-regular - latin */
@font-face {
  font-family: 'Overpass';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('../fonts/Overpass/overpass-v4-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Overpass Regular'), local('Overpass-Regular'),
       url('../fonts/Overpass/overpass-v4-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/Overpass/overpass-v4-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/Overpass/overpass-v4-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('../fonts/Overpass/overpass-v4-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/Overpass/overpass-v4-latin-regular.svg#Overpass') format('svg'); /* Legacy iOS */
}
/* overpass-700 - latin */
@font-face {
  font-family: 'Overpass';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('../fonts/Overpass/overpass-v4-latin-700.eot'); /* IE9 Compat Modes */
  src: local('Overpass Bold'), local('Overpass-Bold'),
       url('../fonts/Overpass/overpass-v4-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/Overpass/overpass-v4-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/Overpass/overpass-v4-latin-700.woff') format('woff'), /* Modern Browsers */
       url('../fonts/Overpass/overpass-v4-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/Overpass/overpass-v4-latin-700.svg#Overpass') format('svg'); /* Legacy iOS */
}

// Custom styles
$vars: (
  primary: (color, #f55650),
  primary-darken: (color, #B02116),
  elegant: (color, #F49448),
  lite: (color, #F3F4F8),
  grey-0: (color, #EAEBEF),
  grey-1: (color, #C5C8CB),
  grey-2: (color, #B0B2B7),
  secondary: (color, #8C9194),
  grey-3: (color, #65696C),
  grey-4: (color, #505456),
  grey-5: (color, #3C3F41),
  dark: (color, #2E2F31),

  ff-head: (font-family, "Archivo Black", Arial Black, sans-serif),
  ff-unique: (font-family, "Muli", sans-serif), //400,700
  ff-sans: (font-family, "Overpass", sans-serif), //300,300i,400,700
  ff-serif: (font-family, "Noto Serif", serif) //400,400i,700
);

// Turn all those variables to CSS Variables
:root {
  @each $var, $val in $vars {
    --#{$var}: #{nth($val, 2)};
  }
}

// Create classes out of those variables
@each $var, $val in $vars {
  .#{$var} {
    #{nth($val, 1)}: var(--#{$var});
  }

  // If type is color then generate a bg color class
  @if nth($val, 1) == color {
    .#{$var}-bg {
      background-color: var(--#{$var});
    }
  }
}

@import "custom";
@import "base";
@import "initial/skeleton";

// css/layout.css
@import "color";
// @import "preloader";
@import "elements";
@import "nav";
@import "lightmenu";
@import "home";
@import "parallax";
@import "work";
@import "about";
@import "quotes";
@import "blog";
@import "post";
@import "contact";
@import "footer";
@import "media-queries";
@import "fawesome";

// @import "initial/font-awesome";
@import "initial/et-line";
// @import "initial/ionicons.min";
@import "initial/owl.carousel";
@import "initial/owl.transitions";
@import "initial/retina";

.big-height {height: 85vh;}

@media only screen and (max-width: 1199px) {
  .cbp-af-header.light.cbp-af-header-shrink {height: 50px;}
  .cbp-af-header.cbp-af-header-shrink .logo img {height: 42px;}
  .cbp-af-header.cbp-af-header-shrink .logo {height: 32px;top: 5px;left:20px;}
  .cbp-af-header.light.cbp-af-header-shrink .menu-mobile {padding-top: 25px; }
  .menu.light>ul { padding-left: 10px;}
  .big-height {height: 70vh;}
}
blockquote {
    position: relative;
    padding-left: 1.5em;
    border-left: 0.3em solid var(--grey-3);
    font-family: var(--ff-unique),sans-serif;
    font-size: 17px;
    line-height: 23px;
    margin-left: 25px;
    padding-bottom: .6em !important;
    margin-right: 3em !important;
    color: var(--secondary) !important;
}
blockquote:before, blockquote:after {
  content: '\201C';
  font-family: var(--ff-sans), sans-serif;
  color: var(--primary);
}
blockquote:after {
  content: '\201D';
}
