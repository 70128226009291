/* #Home
================================================== */

.page-center-wrap{
	position:absolute;
	width:100%;
	top:65%;
	left:0;
	z-index:10;
	text-align:center;
	margin:0 auto;
	transform: translateY(-50%);
	text-align: left;
}
.page-center-wrap .home-big-text{
	display:inline-block;
  font-family: 'var(--ff-head';
	font-size: 100px;
	line-height:160px;
	margin:0 auto;
	text-align:center;
}
.page-center-wrap .home-big-text.other-font{
	font-family: var(--ff-head);
	font-size: 60px;
	line-height:90px;
	letter-spacing: -2.25px;
}
.page-center-wrap .home-small-text{
	display:inline-block;
	font-family: var(--ff-sans);
	font-size: 17px;
	line-height:25px;
  color: var(--lite);
  letter-spacing: 1px;
	margin:0 auto;
	text-align:center;
	 background-color: rgba(24,24,25,.75);
	padding: 1px 5px;
}
.page-center-wrap .home-big-text.other-font.font-left{
	font-size: 45px;
	line-height:70px;
}
.page-center-wrap .home-big-text.other-font.font-left,
.page-center-wrap .home-small-text.font-left {
	text-align: right;
	float:left;
}
.home-background-image {
	background-image:url('../images/engineer-construction-recruiting-face-through-paper-778x700.jpg');
  background-position: 105% 100%;
  background-repeat: no-repeat;
  background-size: 90%;
}



.home-link{
	position:absolute;
	width:100%;
	bottom:30px;
	left:0;
	z-index:10;
}
.link-down{
	position:relative;
	width:8px;
	height:27px;
	float:left;
	background-image:url('../images/down-arrow.png');
	background-size:8px 27px;
	background-position:center center;
	z-index:10;
	margin:10px;
}

.home-carousel-wrap{
	position:relative;
	width:100%;
	height:100vh;
	overflow:hidden;
	z-index:2;
}
#owl-home .item {
	position: relative;
    width:100%;
    display: block;
	height:100vh;
	background-size:cover;
	background-position:center center;
}
#owl-home .item.background-home-image-1 {
	background-image:url('//res.cloudinary.com/infin80/image/upload/f_auto,dpr_auto,q_auto:low,h_1200,c_fill/website/brand-cannon-graphic-web-design-logo-corporate-id-8x5.jpg');
}
#owl-home .item.background-home-image-2 {
	background-image:url('//res.cloudinary.com/infin80/image/upload/f_auto,dpr_auto,q_auto:low,h_1200,c_fill/website/sport-tickets-business-cards-logo-design-letterhead-8x5.jpg');
}


#owl-home.owl-theme .owl-controls{
	position:absolute;
	left:30px;
	text-align: center;
	top:50%;
	z-index:100;
	width:8px;
	z-index:20;
	transform: translateY(-50%);
}
#owl-home.owl-theme .owl-controls .owl-page span{
	background: var(--dark);
	border-radius:50%;
	width:4px;
	height:4px;
	display:block;
  transition: all 300ms linear;
}
#owl-home.owl-theme .owl-controls .owl-page span:hover{
	-webkit-transform: scale(1.8);
	transform: scale(1.8);
}
#owl-home.owl-theme .owl-controls .owl-page.active span {
	-webkit-transform: scale(1.8);
	transform: scale(1.8);
}


/* #Showcase
================================================== */

.case-study-wrapper {
	position:absolute;
	width:100%;
	max-width:500px;
	top:50%;
	left:50%;
	margin-top:30px;
	z-index:10;
	transform: translateY(-50%) translateX(-50%);
}
.case-study-wrapper li {
	list-style:none;
	display:block;
	padding-bottom:30px;
	text-align:center;
	margin:0 auto;
	transition: all 0.2s ease-in-out;
}
.case-study-wrapper li h1 {
	text-align:center;
	display:block;
	font-size:26px;
	padding:0;
	margin:0;
	font-family: var(--ff-head);
	line-height:22px;
	letter-spacing:2px;
	color:var(--dark);
}
.case-study-wrapper li h2 {
	font-size: 13px !important;
	font-family: var(--ff-unique) !important;
	font-weight:400;
	padding:0;
	margin:0;
	line-height:30px;
	letter-spacing:3px;
	text-transform:uppercase;
	color: var(--secondary);
}
.case-study-wrapper li a:hover { color: var(--primary);}
.case-study-wrapper:hover li {
	opacity:0.2;
}
.case-study-wrapper:hover li:hover {
	opacity:1;
}

.light-over-portfolio{
	position:absolute;
	width:100%;
	height:100%;
	top:0;
	left:0;
	z-index:4;
	background-color:#fff;
	opacity:.3;
}

.case-study-images {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	margin:0;
	z-index:2;
}
.case-study-images li {
	transition: none;
	position: absolute;
	width: 100%;
	height: 100%;
	background-size: cover;
	background-position:center center;
	background-repeat:no-repeat;
	top: 0;
	left: 0;
	overflow: hidden;
	list-style:none;
	opacity:0;

	transition: all 0.2s ease-in-out;
}
.case-study-images li.show {
	opacity:1;
}




/* #letters
================================================== */

.home-text{
	position:absolute;
	width:100%;
	top:50%;
	left:0;
	z-index:10;
	transform: translateY(-50%);
	text-align: center;
}
.home-text h1{
	font-size: 3.75em;
  line-height: 1.25;
	color: var(--dark);
	display:inline-block;
	letter-spacing: -2px
}
.home-text h1 span.cross-out{
	position:relative;
	font-size: .7em;
}
.home-text h1 span.cross-out:after{
	content:'';
	position:absolute;
	top:0;
	left:0;
	width:100%;
	height:100%;
	background-image:url('../images/freeh.svg');
	background-repeat:no-repeat;
	background-position:center center;
	background-size:100% 100%;
}
.home-text h1 em {
	font-style: normal;
	font-size: 90%
}
.cd-title {
  position: relative;
}
.cd-title h1 {
}

.cd-words-wrapper {
  display: inline-block;
  position: relative;
  text-align: left;
}
.cd-words-wrapper b {
  display: inline-block;
  position: absolute;
  white-space: nowrap;
  left: 0;
  top: 0;
}
.cd-words-wrapper b.is-visible {
  position: relative;
}
.no-js .cd-words-wrapper b {
  opacity: 0;
}
.no-js .cd-words-wrapper b.is-visible {
  opacity: 1;
}
/* --------------------------------

xtype

-------------------------------- */
.cd-headline.type .cd-words-wrapper {
  vertical-align: top;
  overflow: hidden;
}
.cd-headline.type .cd-words-wrapper::after {
  /* vertical bar */
  content: '';
  position: absolute;
  right: 0;
  top: 50%;
  bottom: auto;
  transform: translateY(-50%);
  height: 90%;
  width: 2px;
}
.cd-headline.type .cd-words-wrapper.waiting::after {
  animation: cd-pulse 1s infinite;
}
.cd-headline.type .cd-words-wrapper.selected {
}
.cd-headline.type .cd-words-wrapper.selected::after {
  visibility: hidden;
}
.cd-headline.type .cd-words-wrapper.selected b {
  color: #fff;
}
.cd-headline.type b {
  visibility: hidden;
}
.cd-headline.type b.is-visible {
  visibility: visible;
}
.cd-headline.type i {
  position: absolute;
  visibility: hidden;
}
.cd-headline.type i.in {
  position: relative;
  visibility: visible;
}

@-webkit-keyframes cd-pulse {
  0% {
    -webkit-transform: translateY(-50%) scale(1);
    opacity: 1;
  }
  40% {
    -webkit-transform: translateY(-50%) scale(0.9);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(-50%) scale(0);
    opacity: 0;
  }
}
@-moz-keyframes cd-pulse {
  0% {
    -moz-transform: translateY(-50%) scale(1);
    opacity: 1;
  }
  40% {
    -moz-transform: translateY(-50%) scale(0.9);
    opacity: 0;
  }
  100% {
    -moz-transform: translateY(-50%) scale(0);
    opacity: 0;
  }
}
@keyframes cd-pulse {
  0% {
    transform: translateY(-50%) scale(1);
    opacity: 1;
  }
  40% {
    transform: translateY(-50%) scale(0.9);
    opacity: 0;
  }
  100% {
    transform: translateY(-50%) scale(0);
    opacity: 0;
  }
}
@media only screen and (min-width: 1400px) {
	.home-background-image {
		background-position: 110% 50%;
    background-size: 60%;
	}
	.home-text {
		text-align: left;
		left: 0;
	}
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
	.home-background-image {
		background-position: 115% 40%;
    background-size: 70%;
	}
	.home-text {
		text-align: left;
		left: 0;
	}
}
@media only screen and (max-width: 800px) {
}

@media only screen and (max-width: 600px) {
}

@media only screen and (min-width: 960px) and (max-width: 1199px) {
	.home-background-image {
    background-position: 50% -30%;
    background-size: 700px;
	}
	.home-text {
    top: 80%;
  }
  .home-text h1{
		font-size: 3em;
		line-height: 1.1em;
	}
}

@media only screen and (min-width: 768px) and (max-width: 959px) {
	.home-background-image {
		background-position: 50% -15%;
    background-size: 700px;
	}
	.home-text {
    top: 75%;
  }
  .home-text h1{
		font-size: 2.95em;
		line-height: 1.15em;
	}
	.home-text h1 span.cross-out{
		font-size: 30px;
		// line-height:56px;
	}
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
	.home-background-image {
		background-position: 50% 5%;
    background-size: 375px;
	}
	.home-text h1{
		font-size: 2.75em;
		line-height: 1.1em;
		margin-top: 130px;
	}

}
@media only screen and (min-width: 320px) and (max-width: 479px) {

}
@media only screen and (max-width: 319px) {
}
