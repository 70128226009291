.section{
    position:relative;
	display:block;
	width:100%;
}
.full-height{
	height:100vh;
}
.big-height{
	height:75vh;
}
.half-height{
	height:50vh;
}
.padding-top-bottom{
	padding-top:120px;
	padding-bottom:120px;
}
.padding-top{
	padding-top:120px;
}
.padding-bottom{
	padding-bottom:120px;
}
.padding-top-big{
	padding-top:220px;
}
.back-white{
	background-color:#fff;
}
.back-grey{
	background-color:#f5f5f5;
}
.back-black{
	background-color:#000;
}
.back-dark{
	background-color:var(--dark);
}
.img-wrap img{
	width:100%;
	display:block;
}
.bigger-z-index{
	z-index:1000;
}

.block-revealer__element {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: #000;
	pointer-events: none;
	opacity: 0;
}
